import Typography from '@/components/Typography';
import React from 'react';
import { twMerge } from 'tailwind-merge';

const ErrorMessage = ({ message, className, ...properties }) => {
    if (message == null) return;

    return (
        <Typography className={twMerge('text-red-900', className)} {...properties}>
            {message}
        </Typography>
    );
};

export default ErrorMessage;
